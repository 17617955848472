import React, { PureComponent, Fragment } from 'react'
import isEmpty from 'lodash/isEmpty'
import qs from 'query-string'
import LicenseFilter from './LicenseFilter'
import LicenseRow from './LicenseRow'
import AddLicenseButton from './Buttons/AddLicenseButton'
import Table from '../Table'
import Paginate from '../Paginate'
import Empty from './Empty'
import Spinner from '../Spinner'
import SortableTableHeader from '../components/SortableTableHeader'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

class LicenseIndex extends PureComponent {
  static defaultProps = {license_filter: '', filtered_licenses: '', spinnerOn: true, currentPage: 1, myLicenses: false, archivedLicenses: false}

  state = { ...this.props, showOrder: false, dropdownItems: [], sortVal: {key: 'created_at', direction: 'desc'} }

  componentDidMount () {
    this.toPage(1)
  }

  componentDidUpdate (prevProps, prevState) {
    $('.licenses-list [data-toggle]').tooltip({container: 'body'})
    if (prevState.sortVal !== this.state.sortVal && this.state.filtered_licenses.length === 0) {
      this.toPage(1)
    }
  }

  onFilterChange = values => {
    this.setState(values)
  }

  toggelMylicenses = values => {
    this.setState({myLicenses: values})
    this.toPage(1)
  }

  toggelArchivedLicenses = values => {
    this.setState({archivedLicenses: values})
    this.toPage(1)
  }

  fetchPage = (page) => fetch(
    `/api/licenses/index_brief.json?${this.params(page)}`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application.json',
        'Content-Type': 'application/json'
      }
    })

  params = (page) => {
    const parameters = {
      organization_id: this.state.organizationId,
      myLicenses: this.state.myLicenses,
      archivedLicenses: this.state.archivedLicenses,
      user_id: this.props.userId,
      order_id: this.props.orderId,
      sort_direction: this.state.sortVal.direction,
      sort_key: this.state.sortVal.key,
      page
    }
    return qs.stringify(parameters)
  }
  onPageNext = () => {
    this.setState({ spinnerOn: true }, async () => {
      await this.onPageNextAction()
      this.setState({ spinnerOn: false })
    })
  }

  onPageNextAction = async values => {
    const nextPage = this.state.currentPage + 1
    try {
      const response = await this.fetchPage(nextPage)
      const json = await response.json()
      this.setState({licenses: json.data, currentPage: nextPage})
    } catch (error) {
      this.setState({licenses: this.props.licenses, currentPage: 1})
    }
  }

  onPageBack = () => {
    this.setState({ spinnerOn: true }, async () => {
      await this.onPageBackAction()
      this.setState({ spinnerOn: false })
    })
  }

  onPageBackAction = async values => {
    const backPage = this.state.currentPage - 1
    try {
      const response = await this.fetchPage(backPage)
      const json = await response.json()
      this.setState({licenses: json.data, currentPage: backPage})
    } catch (error) {
      this.setState({licenses: this.props.licenses, currentPage: 1})
    }
  }

  toPage = (pageNumber) => {
    this.setState({ spinnerOn: true }, async () => {
      await this.toPageAction(pageNumber)
      this.setState({ spinnerOn: false })
    })
  }

  toPageAction = async pageNumber => {
    try {
      const response = await this.fetchPage(pageNumber)
      const json = await response.json()
      const data = json.data
      const showOrder = data.length > 0 ? data[0].attributes.policies.show_order : false
      this.setState({licenses: data, currentPage: pageNumber, showOrder: showOrder, pages: Math.ceil(response.headers.get('Total') / 20)})
    } catch (error) {
      this.setState({licenses: this.props.licenses, currentPage: 1})
    }
  }

  setSortVal = (sortKey) => {
    if (sortKey === this.state.sortVal.key && this.state.sortVal.direction === 'asc') {
      this.setState({ sortVal: {key: sortKey, direction: 'desc' }})
    } else if (sortKey === this.state.sortVal.key && this.state.sortVal.direction === 'desc') {
      this.setState({ sortVal: {key: sortKey, direction: 'asc' }})
    } else {
      this.setState({ sortVal: {key: sortKey, direction: 'asc' }})
    }
  }

  expiresAtHeader = () =>
    <SortableTableHeader
      title='Expires At'
      trackKey='expires_at'
      buttonOnly
      onChange={() => this.setSortVal('expires_at')}
      sortKey={this.state.sortVal.key}
      sortDirection={this.state.sortVal.direction} />

  createdAtHeader = () =>
    <SortableTableHeader
      title='Created At'
      trackKey='created_at'
      buttonOnly
      onChange={() => this.setSortVal('created_at')}
      sortKey={this.state.sortVal.key}
      sortDirection={this.state.sortVal.direction} />

  headers = showOrder => {
    let columns = ['Name']
    if (showOrder) {
      columns.push('Order')
    } else {
      columns.push('Organization')
    }
    columns.push('Activations', this.createdAtHeader(), this.expiresAtHeader(), <AddLicenseButton
      orderId={this.props.orderId}
      organizationId={this.props.organizationId}
      isMortalUser={this.props.isMortalUser}
      fireEyeUser={this.props.fireEyeUser}
      createOnOrg={this.props.createOnOrg} />
    )
    return columns
  }
  /* eslint-disable complexity */
  render () {
    return (
      <Fragment>
        <div className='col-md-12 licenses-list'>
          {this.props.showHeader && <div className='row py1'>
            <div className='col-md-3'>
              <h1 className='h3'>
                Licenses
                {this.props.username && (` for ${this.props.username}`)}
              </h1>
            </div>
            <div className='col-md-9'>
              <div className='row'>
                <div className={
                  this.props.a10Org && !this.props.isMortalUser ? 'col-md-6 col-md-offset-0' : (this.props.a10Org || !this.props.isMortalUser ? 'col-md-6 col-md-offset-3' : 'col-md-6 col-md-offset-6')}>
                  <div className='col-sm-1 p0'>
                    {this.state.spinnerOn && <Spinner />}
                  </div>
                  <LicenseFilter
                    items={this.state.dropdownItems}
                    licenses={this.state.licenses}
                    organizationId={this.props.organizationId}
                    orderId={this.props.orderId}
                    userId={this.props.userId}
                    myLicenses={this.state.myLicenses}
                    archivedLicenses={this.state.archivedLicenses}
                    sortDirection={this.state.sortVal.direction}
                    onFilterChange={this.onFilterChange} />
                </div>
                {this.props.a10Org &&
                <div className='col-md-3'>
                  <BootstrapSwitchButton
                    checked={this.state.myLicenses}
                    onlabel={`My Licenses`}
                    width={150}
                    offlabel={`My Licenses`}
                    onChange={(checked) => {
                      this.toggelMylicenses(checked)
                    }}
                  />
                </div>}
                {!this.props.isMortalUser &&
                <div className='col-md-3'>
                  <BootstrapSwitchButton
                    checked={this.state.archivedLicenses}
                    onlabel={`Show Archived`}
                    width={150}
                    offlabel={`Show Archived`}
                    onChange={(checked) => {
                      this.toggelArchivedLicenses(checked)
                    }}
                  />
                </div>}
              </div>
            </div>
          </div>}
          <div className='row'>
            {!this.state.licenses ? (
              <div />
            ) : (
              (this.state.licenses.length > 0 || this.state.license_filter !== '') ? (
                <Fragment>
                  <Table
                    headers={this.headers(this.state.showOrder)}>
                    <LicenseRow
                      licenses={this.state.filtered_licenses.length > 0 ? this.state.filtered_licenses : this.state.licenses} />
                  </Table>
                  {this.state.license_filter === '' && this.state.pages > 1 &&
                  <div className='center'>
                    <Paginate
                      pages={this.state.pages}
                      currentPage={this.state.currentPage}
                      onPageNext={this.onPageNext}
                      onPageBack={this.onPageBack}
                      toPage={this.toPage} />
                  </div>
                  }
                </Fragment>
              ) : (this.state.licenses.length === 0 &&
              <Empty
                organizationId={this.props.organizationId}
                myLicenses={this.props.myLicenses}
                archivedLicenses={this.props.archivedLicenses}
                organizationName={this.props.organizationName}
                fireEyeUser={this.props.fireEyeUser}
                createOnOrg={this.props.createOnOrg} />
              )
            )}
          </div>
        </div>
      </Fragment>
    )
  }
  /* eslint-enable complexity */
}

export default LicenseIndex
