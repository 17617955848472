import React, { useState } from 'react'

import { FormInput, FormWrap, FormLabel } from '../components/Form'
import SkuSelect from './SkuSelect'
import Checkbox from './../components/Checkbox'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../assets/stylesheets/components/ExpirationField.scss'
import moment from 'moment'

const getSortedOptions = obj => Object.entries(obj).map(([name, values]) => ({
  value: name, label: values.title
}))

export const ReportSkuFilters = (props) => {
  const { platform, product } = props.reportstate
  const licenseType = props.reportstate.license_type

  const modelSelectOptions = {
    license: { title: 'Licenses' },
    activation: { title: 'License Activations' },
    organization: { title: 'Organizations' },
    user: { title: 'Users' },
    trial_lead: { title: 'Trial Leads' },
    license_pool: { title: 'License Pools' },
    elm: { title: 'ELM Activations' }
  }

  const archivedSelectOptions = {
    unarchived: { title: 'Exclude archived records'},
    archived: { title: 'Only archived records'},
    archived_and_unarchived: { title: 'Include archived and unarchived reports'}
  }

  const withActivationsSelectOptions = {
    empty: { title: 'With or without activations'},
    true: { title: 'With activations' },
    false: { title: 'Without activations' }
  }

  const withRmaSelectOptions = {
    true: { title: 'With pending RMA requests' },
    false: { title: 'Without pending RMA requests' }
  }

  const withLicensesSelectOptions = {
    true: { title: 'With licenses' },
    false: { title: 'Without licenses' }
  }

  const withLicensePoolsSelectOptions = {
    true: { title: 'With license pools' },
    false: { title: 'Without license pools' }
  }

  const withAppliancesSelectOptions = {
    true: { title: 'With appliances' },
    false: { title: 'Without appliances' }
  }

  const withDataUsageSelectOptions = {
    true: { title: 'With data usage' },
    false: { title: 'Without data usage' }
  }

  const expiresBySelectOptions = {
    empty: { title: 'Expires anytime' },
    expires_by: { title: 'Expires by' }
  }

  const trialSelectOptions = {
    not_trial: { title: 'Exclude Trial license' },
    only_trial: { title: 'Only Trial' },
    both: { title: 'Any' }
  }

  const createdAtSelectOptions = {
    this_year: { title: 'Created this year'},
    this_month: { title: 'Created this month' },
    today: { title: 'Created today' },
    range: { title: 'Created in range'},
    created_anytime: { title: 'Created anytime'}
  }

  const createdBySelectOptions = {
    anyone: { title: 'Created by anyone' },
    sales_admin: { title: 'Created by a sales admin' },
    oracle_automation: { title: 'Created by an oracle user' },
    oracle_and_sales: { title: 'Created by a sales admin or oracle user' },
    employee: { title: 'Created by an employee' },
    engineer: { title: 'Created by an engineer' },
    support: { title: 'Created by a support user' },
    standard_user: { title: 'Created by a standard user' }
  }

  const ownedBySelectOptions = {
    customer: { title: 'Customer Owned' },
    employee: { title: 'Employee Owned' },
    any: { title: 'Any' }
  }

  const trialTypeSelectOptions = {
    all: { title: 'All' },
    flexpool: { title: 'Flexpool' },
    harmony: { title: 'Harmony' },
    'harmony-japanese': { title: 'Harmony Japanese' },
    lightning: { title: 'Lightning' },
    vthunder: { title: 'vThunder' },
    'vthunder-japanese': { title: 'vThunder Japanese'}
  }

  const show_summary = (props.reportstate.show_summary === 'on') ? true : false
  const show_trial = (props.reportstate.trial === 'on') ? true : false
  const show_expired = (props.reportstate.expired === 'on') ? true : false
  const show_exported = (props.reportstate.exported === 'on') ? true : false
  const users_with_access = (props.reportstate.users_with_access === 'on') ? true : false

  const [model, setModel] = useState(props.reportstate.model)
  const [archived, setArchived] = useState(props.reportstate.archived || getSortedOptions(archivedSelectOptions)[0].value)
  const [createdBy, setCreatedBy] = useState(props.reportstate.created_by || getSortedOptions(createdBySelectOptions)[0].value)
  const [withActivations, setWithActivations] = useState(props.reportstate.with_activations || getSortedOptions(withActivationsSelectOptions)[0].value)
  const [withRmaRequests, setWithRmaRequests] = useState(props.reportstate.with_rma_requests)
  const [withLicenses, setWithLicenses] = useState(props.reportstate.with_licenses)
  const [withLicensePools, setWithLicensePools] = useState(props.reportstate.with_license_pools)
  const [withAppliances, setWithAppliances] = useState(props.reportstate.with_appliances)
  const [withDataUsage, setWithDataUsage] = useState(props.reportstate.with_data_usage)
  const [expiresBy, setExpiresBy] = useState(props.reportstate.expires_by_select || getSortedOptions(expiresBySelectOptions)[0].value)
  const [expireDate, setExpireDate] = useState(props.reportstate.expire_date || moment())
  const [createdAt, setCreatedAt] = useState(props.reportstate.created_at || getSortedOptions(createdAtSelectOptions)[0].value)
  const [createdRangeStart, setCreatedRangeStart] = useState(props.reportstate.created_range_begin || moment().subtract(1, 'year').toDate())
  const [createdRangeEnd, setCreatedRangeEnd] = useState(props.reportstate.created_range_end || new Date())
  const [ownedBy, setOwnedBy] = useState(props.reportstate.owned_by_select)
  const [trialType, setTrialType] = useState(props.reportstate.trial_type)

  const [organizationFilter, setOrganizationFilter] = useState(props.organizationfilter || '')
  const [sku, setSku] = useState({ platform, product, licenseType })

  const [showSummary, setShowSummary] = useState(show_summary)
  const [trial, setTrial] = useState(props.reportstate.trial_select || getSortedOptions(trialSelectOptions)[0].value)
  const [expired, setExpired] = useState(show_expired)
  const [exported, setExported] = useState(show_exported)
  const [userWithAccess, setUserWithAccess] = useState(users_with_access)

  return (
    <div>
      <FormInput
        type='select'
        label='Record'
        placeholder={'Select a record'}
        required={true}
        id='record'
        name='report_form[model]'
        value={model}
        onChange={(model) => setModel(model.target.value)}
        children={getSortedOptions(modelSelectOptions)}
      />
      <FormInput
        type='select'
        skipLabel={true}
        id='archived'
        name='report_form[archived]'
        value={archived}
        onChange={(e) => setArchived(e.target.value)}
        children={getSortedOptions(archivedSelectOptions)}
      />

      { (model === 'trial_lead') && <FormInput
        type='select'
        label='Trial Type'
        id='trial_type'
        name='report_form[trial_type]'
        value={trialType}
        onChange={(e) => setTrialType(e.target.value)}
        children={getSortedOptions(trialTypeSelectOptions)}
      />
      }

      { (model === 'user') && <FormInput
        type='select'
        label='With or without pending RMA requests'
        id='with_rma_requests'
        name='report_form[with_rma_requests]'
        value={withRmaRequests}
        onChange={(e) => setWithRmaRequests(e.target.value)}
        children={getSortedOptions(withRmaSelectOptions)}
      />
      }

      { (model === 'user' || model === 'organization') &&
        <FormInput
          type='select'
          label='With or without licenses'
          id='with_licenses'
          name='report_form[with_licenses]'
          value={withLicenses}
          onChange={(e) => setWithLicenses(e.target.value)}
          children={getSortedOptions(withLicensesSelectOptions)}
        /> && <FormInput
        type='select'
        label='With or without license pools'
        id='with_license_pools'
        name='report_form[with_license_pools]'
        value={withLicensePools}
        onChange={(e) => setWithLicensePools(e.target.value)}
        children={getSortedOptions(withLicensePoolsSelectOptions)}
      />
      }

      {
        (model === 'license_pool') &&
        <FormInput
          type='select'
          label='With or without appliances'
          id='with_appliances'
          name='report_form[with_appliances]'
          value={withAppliances}
          onChange={(e) => setWithAppliances(e.target.value)}
          children={getSortedOptions(withAppliancesSelectOptions)}
        /> && <FormInput
          type='select'
          label='With or without data usage'
          id='with_data_usage'
          name='report_form[with_data_usage]'
          value={withDataUsage}
          onChange={(e) => setWithDataUsage(e.target.value)}
          children={getSortedOptions(withDataUsageSelectOptions)}
        />
      }

      { (model === 'license' || model === 'activation' || model === 'license_pool' || model === 'user' || model === 'elm') && <FormInput
        type='text'
        className='form-control'
        label={'For organization'}
        id='organization_filter'
        name='organization_filter'
        value={organizationFilter}
        onChange={(e) => setOrganizationFilter(e.target.value)}
      />
      }

      {
        (model === 'license' || model === 'activation') && <div>
          <SkuSelect
            name='report_form[license_type]'
            sku={sku}
            onSkuChange={setSku}
            platformfieldname='report_form[platform]'
            productfieldname='report_form[product]'
            platformplaceholder='Any'
            productplaceholder='Any'
            licensetypeplaceholder='Any'
            required={false}
            isNew={true}
            addon={false}
            {...props}
          />
          <FormInput
            type='select'
            label='Owned By'
            id='owned_by_select'
            name='report_form[owned_by_select]'
            value={ownedBy}
            onChange={(e) => setOwnedBy(e.target.value)}
            children={getSortedOptions(ownedBySelectOptions)}
          />
          {
            (model !== 'activation') &&
              <FormWrap>
                <Checkbox
                  name='report_form[exported]'
                  label='Only exported to ELM'
                  checked={!!exported}
                  onChange={() => setExported(!exported)}
                  {...props} />
              </FormWrap>
          }
          {
            (model === 'license') &&
              <FormWrap>
                <Checkbox
                  name='report_form[users_with_access]'
                  label='Include Users with Access'
                  checked={!!userWithAccess}
                  onChange={() => setUserWithAccess(!userWithAccess)}
                  {...props} />
              </FormWrap>
          }

          <FormInput
            label='Trial'
            type='select'
            id='trial_select'
            name='report_form[trial_select]'
            value={trial}
            onChange={(e) => setTrial(e.target.value)}
            children={getSortedOptions(trialSelectOptions)}
          />

          <FormInput
            type='select'
            skipLabel={true}
            id='expires_by_select'
            name='report_form[expires_by_select]'
            value={expiresBy}
            onChange={(e) => setExpiresBy(e.target.value)}
            children={getSortedOptions(expiresBySelectOptions)}
          />
        </div>
      }
      {
        expiresBy === 'expires_by' && <div>
          <DatePicker
            className='form-control'
            name='report_form[expire_date]'
            selected={moment(expireDate, 'MM/DD/YYYY')}
            onChange={(e) => setExpireDate(moment(e, 'MM/DD/YYYY'))}
          />
          <FormWrap className='py1'>
            <Checkbox
              name='report_form[expired]'
              label='Include already expired'
              checked={!!expired}
              onChange={() => setExpired(!expired)}
              {...props} />
          </FormWrap>
        </div>
      }

      {
        (model === 'elm') && <div>
          <FormInput
            type='select'
            label='Owned By'
            id='owned_by_select'
            name='report_form[owned_by_select]'
            value={ownedBy}
            onChange={(e) => setOwnedBy(e.target.value)}
            children={getSortedOptions(ownedBySelectOptions)}
          />
        </div>
      }

      <FormInput
        type='select'
        skipLabel={true}
        id='created_at'
        name='report_form[created_at]'
        value={createdAt}
        onChange={(e) => setCreatedAt(e.target.value)}
        children={getSortedOptions(createdAtSelectOptions)}
      />
      { createdAt === 'range' && <div>
        <FormWrap className='mb1'>
          <FormLabel children='From'/>
          <DatePicker
            className='form-control'
            name='report_form[created_range_begin]'
            selected={moment(createdRangeStart, 'MM/DD/YYYY')}
            onChange={(e) => setCreatedRangeStart(moment(e, 'MM/DD/YYYY'))}
          />
        </FormWrap>
        <FormWrap className='mb2'>
          <FormLabel children='Through'/>
          <DatePicker
            className='form-control'
            name='report_form[created_range_end]'
            selected={moment(createdRangeEnd, 'MM/DD/YYYY')}
            onChange={(e) => setCreatedRangeEnd(moment(e, 'MM/DD/YYYY'))}
          />
        </FormWrap>
      </div>
      }
      { (model !== 'elm' && model !== 'trial_lead' && model !== 'license_pool') &&
        <FormInput
          type='select'
          skipLabel={true}
          id='created_by'
          name='report_form[created_by]'
          value={createdBy}
          onChange={(e) => setCreatedBy(e.target.value)}
          children={getSortedOptions(createdBySelectOptions)}
        />
      }

      { (model === 'license') && <>
        <FormInput
          type='select'
          skipLabel={true}
          id='with_activations'
          name='report_form[with_activations]'
          value={withActivations}
          onChange={(e) => setWithActivations(e.target.value)}
          children={getSortedOptions(withActivationsSelectOptions)}
        />
        {
          !!sku.platform && <FormWrap className='mb1'>
            <Checkbox
              name='report_form[show_summary]'
              label='Show summary analytics'
              checked={!!showSummary}
              onChange={() => setShowSummary(!showSummary)}
              {...props} />
          </FormWrap>
        }
      </>
      }
    </div>
  )
}

export default ReportSkuFilters
