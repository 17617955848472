import React, { Component } from 'react'

import Box from '../components/Box'
import {
  Checkbox,
  Delete,
  Email,
  Heading,
  NewUserButton,
  NotifyAll,
  UserRow
} from '../components/AuthorizedUsers'
import { FormGroup, FormSection } from '../components/Form'
import { uid } from '../../util'
import { map } from '../../util/object'


const initUsers = (users = [], notify) => users.reduce(
  (obj, user) => ({ ...obj, [uid()]: { ...user, notify } }),
  {}
)

const newUser = (opts = {}) => ({
  [uid()]: { email: '', isNew: true, canDelete: true, notify: false, ...opts }
})

const userPayload = users => (
  Object.values(users)
    .filter(u => u.email)
    .map(({ email, destroy, id, notify }) => ({ email, destroy, id, notify }))
)

export class AuthorizedUsers extends Component {
  static defaultProps = {
    canNotify: true,
    required: true
  }

  state = {
    users: {
      ...initUsers(this.props.users, this.props.canNotify),
      ...newUser({ notify: this.props.canNotify })
    }
  }

  addUser = () => {
    const users = { ...this.state.users, ...newUser() }
    this.changeState({ users })
  }

  handleDelete = key => {
    const user = this.state.users[key]

    if (!user.isNew) {
      const { ...users } = this.state.users
      users[key] = { ...users, destroy: true }
      this.changeState({ users })
    } else {
      const { [key]: user, ...users } = this.state.users
      this.changeState({ users })
    }
  }

  handleNotify = (key, { target: { checked: notify } }) => {
    const { ...users } = this.state.users
    users[key] = { ...users[key], notify }
    this.changeState({ users })
  }

  selectAll = ({ target: { checked: notify } }) => {
    const users = map(this.state.users)(
      ([k, v]) => [k, { ...v, notify }])
    this.changeState({ users })
  }

  handleEmailChange = (key, { target: { value } }) => {
    const { ...users } = this.state.users
    users[key] = { ...users[key], email: value }
    this.changeState({ users })
  }

  changeState = values => {
    const { onChange, name } = this.props
    this.setState(
      { ...values },
      () => {
        const value = userPayload(this.state.users)
        onChange({ name, value })
      }
    )
  }

  render () {
    const { canNotify, required, value, onChange, name } = this.props
    const users = Object.entries(this.state.users).filter(([_, u]) => !u.deleted)
    const allChecked = users.every(([_, u]) => u.notify)
    const hasNoEmails = !users.some(([_, u]) => u.email)
    const isRequired = required && hasNoEmails
    const processedValue = userPayload(this.state.users)
    if (!value && (processedValue && processedValue.length > 0)) {
      onChange({name: name, value: processedValue })
    }

    return (
      <FormSection>
        <Heading
          canNotify={canNotify}
          required={isRequired}
        />
        <Box className='auto-scrollable'>
          {users.map(([key, user], i) =>
            <UserRow key={key}>
              <div className="row">
                <Email
                  required={isRequired && i === 0}
                  readOnly={!user.isNew}
                  value={user.email}
                  onChange={e => this.handleEmailChange(key, e)}
                />
                {canNotify &&
                  <Checkbox
                    checked={!!user.notify}
                    onChange={e => this.handleNotify(key, e)}
                  />
                }
                {user.canDelete && users.length > 1 &&
                  <Delete onClick={() => this.handleDelete(key)} />
                }
              </div>
            </UserRow>
          )}
        </Box>
        <FormGroup className='container-fluid navbar-btn p0'>
          <div className="row">
            <NewUserButton onClick={this.addUser} />
            {canNotify &&
              <NotifyAll checked={allChecked} onChange={this.selectAll} />
            }
          </div>
        </FormGroup>
      </FormSection>
    )
  }
}

export default AuthorizedUsers
